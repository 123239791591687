export const metisPools = [
  {
    id: 'netswap-weth-metis',
    name: 'WETH-METIS LP',
    token: 'WETH-METIS LP',
    tokenDescription: 'Netswap',
    tokenAddress: '0x59051B5F5172b69E66869048Dc69D35dB0B3610d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXNetswapWETH-METIS',
    earnedTokenAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    earnContractAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'netswap-weth-metis',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Netswap',
    assets: ['WETH', 'METIS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://netswap.io/#/add/0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000/0x420000000000000000000000000000000000000A',
    buyTokenUrl:
      'https://netswap.io/#/swap?inputCurrency=0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000&outputCurrency=0x420000000000000000000000000000000000000A',
  },
  {
    id: 'netswap-wbtc-metis',
    name: 'WBTC-METIS',
    token: 'WBTC-METIS LP',
    tokenDescription: 'Netswap',
    tokenAddress: '0xE0cc462fe369146BAef2306EC6B4BF26704eE84e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXNetswapWBTC-METIS',
    earnedTokenAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    earnContractAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'netswap-wbtc-metis',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Netswap',
    assets: ['WBTC', 'METIS'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl: 'https://netswap.io/#/add/METIS/0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4',
    buyTokenUrl:
      'https://netswap.io/#/swap?inputCurrency=METIS&outputCurrency=0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4',
    createdAt: 1645533903,
  },
  {
    id: 'netswap-weth-m.usdt',
    name: 'm.USDT-WETH LP',
    token: 'm.USDT-WETH LP',
    tokenDescription: 'Netswap',
    tokenAddress: '0x4Db4CE7f5b43A6B455D3c3057b63A083b09b8376',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXNetswapm.USDT-WETH',
    earnedTokenAddress: '0x815A43dA6575a03Df12DB07Ed64460667634E9D0',
    earnContractAddress: '0x815A43dA6575a03Df12DB07Ed64460667634E9D0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'netswap-weth-m.usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Netswap',
    assets: ['mUSDT', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://netswap.io/#/add/0x420000000000000000000000000000000000000A/0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
    buyTokenUrl:
      'https://netswap.io/#/swap?inputCurrency=0x420000000000000000000000000000000000000A&outputCurrency=0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
    createdAt: 1642365495,
  },
  {
    id: 'netswap-wbtc-m.usdt',
    name: 'WBTC-m.USDT',
    token: 'WBTC-m.USDT LP',
    tokenDescription: 'Netswap',
    tokenAddress: '0xAd9b903451dfdc3D79d2021289F9d864fd8c8119',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXNetswapWBTC-m.USDT',
    earnedTokenAddress: '0xE43C8c88DE2005AdDA2F9d70ca43CfA2b7279645',
    earnContractAddress: '0xE43C8c88DE2005AdDA2F9d70ca43CfA2b7279645',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'netswap-wbtc-m.usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Netswap',
    assets: ['WBTC', 'mUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://netswap.io/#/add/0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4/0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
    buyTokenUrl:
      'https://netswap.io/#/swap?inputCurrency=0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC&outputCurrency=0xa5B55ab1dAF0F8e1EFc0eB1931a957fd89B918f4',
    createdAt: 1645536038,
  },
];

export const celoPools = [
  {
    id: 'sushi-celo-wethv2-celo',
    name: 'CELO-WETH LP',
    token: 'CELO-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x517f717f0AE11efc2AF8943071B66d77DfaFc9fC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXSushiCELO-WETHv2',
    earnedTokenAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    earnContractAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-wethv2-celo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['CELO', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x471EcE3750Da237f93B8E339c536989b8978a438/0x122013fd7dF1C6F636a5bb8f03108E876548b455',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x471EcE3750Da237f93B8E339c536989b8978a438&outputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455',
  },
  {
    id: 'sushi-celo-weth-daiv2',
    name: 'DAI-WETH LP',
    token: 'DAI-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0xCCd9d850eF40f19566cd8df950765E9A1a0B9Ef2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXSushiWETH-DAIv2',
    earnedTokenAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    earnContractAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-weth-daiv2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['DAI', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x122013fd7dF1C6F636a5bb8f03108E876548b455/0xE4fE50cdD716522A56204352f00AA110F731932d',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455a&outputCurrency=0xE4fE50cdD716522A56204352f00AA110F731932d',
  },
  {
    id: 'sushi-celo-weth-usdt',
    name: 'USDT-WETH LP',
    token: 'USDT-WETH LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0xC77398cfb7B0F7ab42baFC02ABc20A69CE8cEf7f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXSushiWETH-USDT',
    earnedTokenAddress: '0x94763e4B792bAdbd2EF837E872fE32f270FAAD24',
    earnContractAddress: '0x94763e4B792bAdbd2EF837E872fE32f270FAAD24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-weth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['USDT', 'WETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x122013fd7dF1C6F636a5bb8f03108E876548b455/0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x122013fd7dF1C6F636a5bb8f03108E876548b455a&outputCurrency=0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
  },
  {
    id: 'sushi-celo-cusd-ceurv2',
    name: 'cUSD-cEUR LP',
    token: 'cUSD-cEUR LPv2',
    tokenDescription: 'Sushi',
    tokenAddress: '0x0b655E7D966CB27998af94AA5719ab7BFe07D3b3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXSushicUSD-cEURv2',
    earnedTokenAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    earnContractAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-celo-cusd-ceurv2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    withdrawalFee: '0%',
    assets: ['cUSD', 'cEUR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.sushi.com/add/0x765DE816845861e75A25fCA122bb6898B8B1282a/0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=0x765DE816845861e75A25fCA122bb6898B8B1282a&outputCurrency=0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
  },
];

export const fantomPools = [
  {
    id: 'tomb-tshare-ftm',
    logo: 'fantom/TSHARE-FTM.png',
    name: 'TSHARE-FTM LP',
    token: 'TSHARE-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x4733bc45eF91cF7CcEcaeeDb794727075fB209F2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXTombTSHARE-FTM',
    earnedTokenAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    // earnedTokenAddress: '0x8F718Dc40C6E6435B3A52eca8590B42C9A762Db9',
    earnContractAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    // earnContractAddress: '0x8F718Dc40C6E6435B3A52eca8590B42C9A762Db9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tshare-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'TombFinance',
    assets: ['TSHARE', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
  },
  {
    id: 'tomb-tomb-ftm',
    logo: 'fantom/TOMB-FTM.png',
    name: 'TOMB-FTM LP',
    token: 'TOMB-FTM LP',
    tokenDescription: 'SpookySwap (Tomb)',
    tokenAddress: '0x2A651563C9d3Af67aE0388a5c8F89b867038089e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXTombTOMB-FTM',
    earnedTokenAddress: '0xF4Df9e9c6E4a8bEd20Ed6A4f19dB8B1b61014455',
    earnContractAddress: '0xF4Df9e9c6E4a8bEd20Ed6A4f19dB8B1b61014455',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'tomb-tomb-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'TombFinance',
    assets: ['TOMB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
  },
  {
    id: 'scream-frax',
    logo: 'single-assets/FRAX.svg',
    name: 'FRAX',
    token: 'FRAX',
    tokenDescription: 'Scream',
    tokenAddress: '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamFRAX',
    earnedTokenAddress: '0x5fAF87e561f97993c55b92816691E141407380F4',
    earnContractAddress: '0x5fAF87e561f97993c55b92816691E141407380F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FRAX',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['FRAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'ALGO_STABLE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://app.frax.finance/crosschain',
  },
  {
    id: 'curve-ftm-tricrypto',
    logo: 'uncategorized/ATRICRYPTO.png',
    name: 'TriCrypto',
    token: 'crv3crypto',
    tokenDescription: 'Curve',
    tokenAddress: '0x58e57cA18B7A47112b877E31929798Cd3D703b0f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCurveTriCrypto',
    earnedTokenAddress: '0x58e5000acCDe8513ABbF861c2059266aEf6818bF',
    earnContractAddress: '0x58e5000acCDe8513ABbF861c2059266aEf6818bF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-tricrypto',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['fUSDT', 'WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/tricrypto/deposit',
  },
  {
    id: 'curve-ftm-ren',
    logo: 'single-assets/renBTC.png',
    name: 'WBTC/renBTC',
    token: 'btcCRV',
    tokenDescription: 'Curve',
    tokenAddress: '0x5B5CFE992AdAC0C9D48E05854B2d91C73a003858',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCurveRenBTC',
    earnedTokenAddress: '0xa359020F295ea2718AA1F067C14782f90044C452',
    earnContractAddress: '0xa359020F295ea2718AA1F067C14782f90044C452',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-ren',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['WBTC', 'renBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://ftm.curve.fi/ren/deposit',
  },
  {
    id: 'curve-ftm-f-4pool',
    name: 'UST/FRAX/fUSDT/USDC',
    token: '4POOL-f',
    tokenDescription: 'Curve',
    tokenAddress: '0x9dc516a18775d492c9f061211C8a3FDCd476558d',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCurve4pool',
    earnedTokenAddress: '0x95fF2E89B8381fE906de6C090F04cc9C6c23F762',
    earnContractAddress: '0x95fF2E89B8381fE906de6C090F04cc9C6c23F762',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'curve-ftm-f-4pool',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Curve',
    assets: ['UST', 'FRAX', 'fUSDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl: 'https://ftm.curve.fi/factory/84/deposit',
    createdAt: 1651152132,
  },
  {
    id: 'boo-usdc-dei',
    name: 'DEI-USDC LP',
    token: 'DEI-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xD343b8361Ce32A9e570C1fC8D4244d32848df88B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooUSDC-DEI',
    earnedTokenAddress: '0x5fAF87e561f97993c55b92816691E141407380F4',
    earnContractAddress: '0x5fAF87e561f97993c55b92816691E141407380F4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-dei',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['DEI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spooky.fi/#/add/0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    buyTokenUrl:
      'https://spooky.fi/#/swap?outputCurrency=0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
    createdAt: 1649955002,
  },
  {
    id: 'spirit-dai-usdc',
    name: 'DAI-USDC LP',
    token: 'DAI-USDC SLP',
    tokenDescription: 'SpiritSwap',
    tokenAddress: '0x9606D683d03f012DDa296eF0ae9261207C4A5847',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXSpiritUSDC-DAI',
    earnedTokenAddress: '0xeE8104Ca200Bfe8B3A424edf3cA8F60a89415E4E',
    earnContractAddress: '0xeE8104Ca200Bfe8B3A424edf3cA8F60a89415E4E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'spirit-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://swap.spiritswap.finance/#/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    buyTokenUrl:
      'https://swap.spiritswap.finance/#/exchange/swap/0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
  },
  {
    id: 'scream-spell',
    logo: 'single-assets/SPELL.png',
    name: 'SPELL',
    token: 'SPELL',
    tokenDescription: 'Scream',
    tokenAddress: '0x468003B688943977e6130F4F68F23aad939a1040',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamSPELL',
    earnedTokenAddress: '0xe57A4ae1D7261593B05DF941EedD8B66608D56ea',
    earnContractAddress: '0xe57A4ae1D7261593B05DF941EedD8B66608D56ea',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SPELL',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['SPELL'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    showWarning: false,
    warning: 'liquidity',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x468003B688943977e6130F4F68F23aad939a1040',
  },
  {
    id: 'scream-crv',
    logo: 'single-assets/CRV.png',
    name: 'CRV',
    token: 'CRV',
    tokenDescription: 'Scream',
    tokenAddress: '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamCRV',
    earnedTokenAddress: '0x9a1dbd0f18ec5EE0c18d03eC3f261Ab74e91fA1f',
    earnContractAddress: '0x9a1dbd0f18ec5EE0c18d03eC3f261Ab74e91fA1f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'CRV',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['CRV'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x1E4F97b9f9F913c46F1632781732927B9019C68b',
  },
  {
    id: 'scream-dola',
    logo: 'single-assets/DOLA.png',
    name: 'DOLA',
    token: 'DOLA',
    tokenDescription: 'Scream',
    tokenAddress: '0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamDOLA',
    earnedTokenAddress: '0x154449345200789010E2042BC35C2B5DDC8c8900',
    earnContractAddress: '0x154449345200789010E2042BC35C2B5DDC8c8900',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'DOLA',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['DOLA'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
  },
  {
    id: 'scream-tusd',
    logo: 'single-assets/TUSD.png',
    name: 'TUSD',
    token: 'TUSD',
    tokenDescription: 'Scream',
    tokenAddress: '0x9879aBDea01a879644185341F7aF7d8343556B7a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamTUSD',
    earnedTokenAddress: '0xe3bdBA3EF88a4e3e4301CCCa938f20AB3CADD8D8',
    earnContractAddress: '0xe3bdBA3EF88a4e3e4301CCCa938f20AB3CADD8D8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'TUSD',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['TUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    showWarning: false,
    warning: 'liquidity',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x9879aBDea01a879644185341F7aF7d8343556B7a',
  },
  {
    id: 'scream-fusdt',
    logo: 'single-assets/USDT.svg',
    name: 'fUSDT',
    token: 'fUSDT',
    tokenDescription: 'Scream',
    tokenAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamfUSDT',
    earnedTokenAddress: '0x4b95ed4457557aEEF471C83ed8C806940c46E163',
    earnContractAddress: '0x4b95ed4457557aEEF471C83ed8C806940c46E163',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDT',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['fUSDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x049d68029688eAbF473097a2fC38ef61633A3C7A',
  },
  {
    id: 'scream-mim',
    logo: 'single-assets/MIM.png',
    name: 'MIM',
    token: 'MIM',
    tokenDescription: 'Scream',
    tokenAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamMIM',
    earnedTokenAddress: '0xE9A0B41d19742a259663aa45E5CC50672C0342ab',
    earnContractAddress: '0xE9A0B41d19742a259663aa45E5CC50672C0342ab',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'MIM',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['MIM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'NO_AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x82f0B8B456c1A451378467398982d4834b6829c1',
  },
  {
    id: 'scream-usdc',
    logo: 'single-assets/USDC.svg',
    name: 'USDC',
    token: 'USDC',
    tokenDescription: 'Scream',
    tokenAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    tokenDecimals: 6,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXScreamUSDC',
    earnedTokenAddress: '0x17addCb555529bd71B7cbeb8CdABf964229F9FD5',
    earnContractAddress: '0x17addCb555529bd71B7cbeb8CdABf964229F9FD5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'USDC',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Scream',
    assets: ['USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
  },
  {
    id: 'boo-usdc-mai',
    name: 'USDC-MAI',
    token: 'USDC-MAI LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x4dE9f0ED95de2461B6dB1660f908348c42893b1A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooUSDC-MAI',
    earnedTokenAddress: '0x67d6cCf5b00E08338Df4314532CB772C3a20a228',
    earnContractAddress: '0x67d6cCf5b00E08338Df4314532CB772C3a20a228',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['USDC', 'MAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
  },
  {
    id: 'boo-btc-eth',
    name: 'WBTC-ETH LP',
    token: 'WBTC-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xEc454EdA10accdD66209C57aF8C12924556F3aBD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooBTC-ETH',
    earnedTokenAddress: '0x6b300c636F416208ae643f9065212fCf2bda3692',
    earnContractAddress: '0x6b300c636F416208ae643f9065212fCf2bda3692',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-btc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['WBTC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x74b23882a30290451A17c44f4F05243b6b58C76d/0x321162Cd933E2Be498Cd2267a90534A804051b11',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d&outputCurrency=0x321162Cd933E2Be498Cd2267a90534A804051b11',
  },
  {
    id: 'boo-usdc-tusd',
    name: 'TUSD-USDC LP',
    token: 'TUSD-USDC LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x12692B3bf8dd9Aa1d2E721d1a79efD0C244d7d96',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooUSDC-TUSD',
    earnedTokenAddress: '0x910b76E7E7c5FAb920ca3Ea16cDc830f1E575aDa',
    earnContractAddress: '0x910b76E7E7c5FAb920ca3Ea16cDc830f1E575aDa',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-usdc-tusd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75/0x9879aBDea01a879644185341F7aF7d8343556B7a',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x04068DA6C83AFCFA0e13ba15A6696662335D5B75&outputCurrency=0x9879aBDea01a879644185341F7aF7d8343556B7a',
  },
  {
    id: 'boo-wftm-treeb',
    name: 'TREEB-FTM LP',
    token: 'TREEB-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0xe8b72a866b8D59F5c13D2ADEF96E40A3EF5b3152',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooTREEB-FTM',
    earnedTokenAddress: '0xE0F6f86faf41D89aB28350Ef4fff6A83452FA02F',
    earnContractAddress: '0xE0F6f86faf41D89aB28350Ef4fff6A83452FA02F',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-treeb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['TREEB', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
  },
  {
    id: 'boo-yfi-eth',
    name: 'YFI-ETH LP',
    token: 'YFI-ETH LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0845c0bFe75691B1e21b24351aAc581a7FB6b7Df',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBooYFI-ETH',
    earnedTokenAddress: '0x77cD9C2a702F87a609E4EA9DDFBE4E4754127C1E',
    earnContractAddress: '0x77cD9C2a702F87a609E4EA9DDFBE4E4754127C1E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-yfi-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['YFI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://spookyswap.finance/add/0x29b0Da86e484E1C0029B56e817912d778aC0EC69/0x74b23882a30290451A17c44f4F05243b6b58C76d',
    buyTokenUrl:
      'https://spookyswap.finance/swap?inputCurrency=0x29b0Da86e484E1C0029B56e817912d778aC0EC69&outputCurrency=0x74b23882a30290451A17c44f4F05243b6b58C76d',
  },
  {
    id: 'boo-wftm-wshec',
    name: 'wsHEC-FTM LP',
    token: 'wsHEC-FTM LP',
    tokenDescription: 'SpookySwap',
    tokenAddress: '0x0BfE6f893a6bc443B575Ddf361A30f39aa03e59c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBoowsHEC-FTM',
    earnedTokenAddress: '0xB48dd6785A79cb0045A5A6aC91485a68c4c35a00',
    earnContractAddress: '0xB48dd6785A79cb0045A5A6aC91485a68c4c35a00',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'boo-wftm-wshec',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    assets: ['wsHEC', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://spookyswap.finance/add/FTM/0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
    buyTokenUrl:
      'https://spookyswap.finance/swap?outputCurrency=0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
  },
];

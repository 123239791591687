export const polygonPools = [
  {
    id: 'quick-matic-usdc',
    logo: 'polygon/MATIC-USDC.png',
    name: 'MATIC-USDC LP',
    token: 'MATIC-USDC QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXQuickMATIC-USDC',
    earnedTokenAddress: '0x3BDf87b16aae125da51C76BAd862FAD73d574a98',
    earnContractAddress: '0x3BDf87b16aae125da51C76BAd862FAD73d574a98',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-matic-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['MATIC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/ETH/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    id: 'quick-usdc-dai',
    logo: 'polygon/USDC-DAI.png',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0xf04adBF75cDFc5eD26eeA4bbbb991DB002036Bdd',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXQuickUSDC-DAI',
    earnedTokenAddress: '0x09095577C4625B0AF818056074e8D8249BEe54C3',
    earnContractAddress: '0x09095577C4625B0AF818056074e8D8249BEe54C3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174&outputCurrency=0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  },
  {
    id: 'quick-dai-eth',
    logo: 'polygon/ETH-DAI.png',
    name: 'DAI-ETH LP',
    token: 'DAI-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x4A35582a710E1F4b2030A3F826DA20BfB6703C09',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXQuickDAI-ETH',
    earnedTokenAddress: '0xF4Df9e9c6E4a8bEd20Ed6A4f19dB8B1b61014455',
    earnContractAddress: '0xF4Df9e9c6E4a8bEd20Ed6A4f19dB8B1b61014455',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-dai-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['DAI', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'quick-usdc-usdt',
    logo: 'polygon/USDC-USDT.png',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x2cF7252e74036d1Da831d11089D326296e64a728',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXQuickUSDC-USDT',
    earnedTokenAddress: '0x58e5000acCDe8513ABbF861c2059266aEf6818bF',
    earnContractAddress: '0x58e5000acCDe8513ABbF861c2059266aEf6818bF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
  },
  {
    id: 'quick-aave-eth',
    logo: 'polygon/AAVE-ETH.png',
    name: 'AAVE-ETH LP',
    token: 'AAVE-ETH QLP',
    tokenDescription: 'QuickSwap',
    tokenAddress: '0x90bc3E68Ba8393a3Bf2D79309365089975341a43',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXQuickAAVE-ETH',
    earnedTokenAddress: '0xa359020F295ea2718AA1F067C14782f90044C452',
    earnContractAddress: '0xa359020F295ea2718AA1F067C14782f90044C452',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'quick-aave-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'QuickSwap',
    assets: ['AAVE', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://quickswap.exchange/#/add/0xD6DF932A45C0f255f85145f286eA0b292B21C90B/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=0xD6DF932A45C0f255f85145f286eA0b292B21C90B&outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'ape-eth-matic',
    name: 'ETH-MATIC ALP',
    token: 'ETH-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x6Cf8654e85AB489cA7e70189046D507ebA233613',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeSwapMATIC-ETH',
    earnedTokenAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    earnContractAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-eth-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ETH', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  },
  {
    id: 'ape-banana-matic',
    name: 'BANANA-MATIC ALP',
    token: 'BANANA-MATIC ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x034293F21F1cCE5908BC605CE5850dF2b1059aC0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeSwapBANANA-MATIC',
    earnedTokenAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    earnContractAddress: '0xc6Dd1694212153766d83fc6045617781AeF6cEA9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-banana-matic',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'MATIC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
  },
  {
    id: 'ape-matic-usdt',
    name: 'MATIC-USDT ALP',
    token: 'MATIC-USDT ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeSwapMATIC-USDT',
    earnedTokenAddress: '0x94763e4B792bAdbd2EF837E872fE32f270FAAD24',
    earnContractAddress: '0x94763e4B792bAdbd2EF837E872fE32f270FAAD24',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'ape-matic-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['MATIC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'PLATFORM_ESTABLISHED',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.apeswap.finance/add/ETH/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    buyTokenUrl:
      'https://app.apeswap.finance/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
];

export const bscPools = [
  {
    id: 'cakev2-cake-usdt',
    name: 'CAKE-USDT LP',
    token: 'CAKE-USDT LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCakeV2CAKE-USDT',
    earnedTokenAddress: '0x12054CA85c3c46ed10F1E3Df36aaEe0f91bfBE1d',
    earnContractAddress: '0x12054CA85c3c46ed10F1E3Df36aaEe0f91bfBE1d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'USDT'],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0x55d398326f99059fF775485246999027B3197955',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  },
  {
    id: 'cakev2-cake-bnb',
    name: 'CAKE-BNB LP',
    token: 'CAKE-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCakeV2CAKE-BNB',
    // earnedTokenAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
    // earnedTokenAddress: '0x2DD88AF1687e47882e83EBD2726Caf1dd17585Aa',
    earnedTokenAddress: '0xf0365AD372A35c375f7d7D98DdD10A50De84FcFd',
    // earnContractAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
    // earnContractAddress: '0x2DD88AF1687e47882e83EBD2726Caf1dd17585Aa',
    earnContractAddress: '0xf0365AD372A35c375f7d7D98DdD10A50De84FcFd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-cake-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['CAKE', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
  },
  {
    id: 'cakev2-usdc-busd',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD LP',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCakeV2USDC-BUSD',
    earnedTokenAddress: '0xeE8104Ca200Bfe8B3A424edf3cA8F60a89415E4E',
    earnContractAddress: '0xeE8104Ca200Bfe8B3A424edf3cA8F60a89415E4E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-usdc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['USDC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x2354ef4df11afacb85a5c7f98b624072eccddbb1/BNB',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
  },
  {
    id: 'cakev2-ust-busd',
    logo: 'busd-pairs/UST-BUSD.svg',
    name: 'UST-BUSD LP',
    token: 'UST-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x05faf555522Fa3F93959F86B41A3808666093210',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCakeV2UST-BUSD',
    earnedTokenAddress: '0xe57A4ae1D7261593B05DF941EedD8B66608D56ea',
    earnContractAddress: '0xe57A4ae1D7261593B05DF941EedD8B66608D56ea',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-ust-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['UST', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x23396cF899Ca06c4472205fC903bDB4de249D6fC&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'cakev2-busd-bnb',
    logo: 'bnb-pairs/BUSD-BNB.svg',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    tokenDecimals: 18,
    earnedToken: 'tokenXCakeV2BNB-BUSD',
    earnedTokenAddress: '0x9a1dbd0f18ec5EE0c18d03eC3f261Ab74e91fA1f',
    earnContractAddress: '0x9a1dbd0f18ec5EE0c18d03eC3f261Ab74e91fA1f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BUSD', 'BNB'],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'cakev2-btcb-busd',
    logo: 'busd-pairs/BTCB-BUSD.svg',
    name: 'BTCB-BUSD LP',
    token: 'BTCB-BUSD LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXCakeV2BTCB-BUSD',
    earnedTokenAddress: '0x154449345200789010E2042BC35C2B5DDC8c8900',
    earnContractAddress: '0x154449345200789010E2042BC35C2B5DDC8c8900',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCB', 'BUSD'],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  },
  {
    id: 'cakev2-btcb-bnb',
    logo: 'bnb-pairs/BTCB-BNB.svg',
    name: 'BTCB-BNB LP',
    token: 'BTCB-BNB LP2',
    tokenDescription: 'PancakeSwap',
    tokenAddress: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
    tokenDecimals: 18,
    earnedToken: 'tokenXCakeV2BNB-BTCB',
    earnedTokenAddress: '0xe3bdBA3EF88a4e3e4301CCCa938f20AB3CADD8D8',
    earnContractAddress: '0xe3bdBA3EF88a4e3e4301CCCa938f20AB3CADD8D8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'cakev2-btcb-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'PancakeSwap',
    assets: ['BTCB', 'BNB'],
    addLiquidityUrl:
      'https://pancakeswap.finance/add/BNB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  },
  {
    id: 'banana-shib-bnb',
    logo: 'bnb-pairs/SHIB-BNB.svg',
    name: 'SHIB-BNB LP',
    token: 'SHIB-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xC0AFB6078981629F7eAe4f2ae93b6DBEA9D7a7e9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeSHIB-BNB',
    earnedTokenAddress: '0xFF8EeDf7F8a38773cc7A54c7f36e6eda0675F5dA',
    earnContractAddress: '0xFF8EeDf7F8a38773cc7A54c7f36e6eda0675F5dA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-shib-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['SHIB', 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  },
  {
    id: 'banana-ada-eth',
    logo: 'eth-pairs/ADA-ETH.svg',
    name: 'ADA-ETH LP',
    token: 'ADA-ETH LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x61FE209E404166a53Cc627d0ae30A65606315dA7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeADA-ETH',
    earnedTokenAddress: '0x4b95ed4457557aEEF471C83ed8C806940c46E163',
    earnContractAddress: '0x4b95ed4457557aEEF471C83ed8C806940c46E163',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-ada-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['ADA', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  },
  {
    id: 'banana-link-bnb',
    logo: 'degens/LINK-BNB.svg',
    name: 'LINK-BNB LP',
    token: 'LINK-BNB ALP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeLINK-BNB',
    earnedTokenAddress: '0x17addCb555529bd71B7cbeb8CdABf964229F9FD5',
    earnContractAddress: '0x17addCb555529bd71B7cbeb8CdABf964229F9FD5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-link-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['LINK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  },
  {
    id: 'banana-dot-bnb',
    logo: 'degens/BANANA-DOT-BNB.svg',
    name: 'DOT-BNB LP',
    token: 'DOT-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeDOT-BNB',
    earnedTokenAddress: '0x480Ea5e87E128Cd517B0D46Cf54edF3a995a20C1',
    earnContractAddress: '0x480Ea5e87E128Cd517B0D46Cf54edF3a995a20C1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-dot-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['DOT', 'BNB'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  },
  {
    id: 'banana-banana-bnb',
    logo: 'degens/banana-bnb.svg',
    name: 'BANANA-BNB LP',
    token: 'BANANA-BNB LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeBANANA-BNB',
    earnedTokenAddress: '0x5F6218834aC6E91bb01211f4399cE0d56551884d',
    earnContractAddress: '0x5F6218834aC6E91bb01211f4399cE0d56551884d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-banana-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BANANA', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
  {
    id: 'banana-busd-usdc',
    logo: 'busd-pairs/BUSD-USDC.svg',
    name: 'BUSD-USDC LP',
    token: 'BUSD-USDC LP',
    tokenDescription: 'ApeSwap',
    tokenAddress: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXApeBUSD-USDC',
    earnedTokenAddress: '0x9990a35C3df7Aa843A6C02aB16Af9dd8023d88ad',
    earnContractAddress: '0x9990a35C3df7Aa843A6C02aB16Af9dd8023d88ad',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'banana-busd-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'ApeSwap',
    assets: ['BUSD', 'USDC'],
    addLiquidityUrl:
      'https://dex.apeswap.finance/#/add/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
    buyTokenUrl:
      'https://dex.apeswap.finance/#/swap?inputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  },
  {
    id: 'belt-4belt',
    logo: 'uncategorized/BELT-VENUSBLP.png',
    name: 'BUSD/USDT/USDC/DAI',
    token: '4BELT LP',
    tokenDescription: 'Belt',
    tokenAddress: '0x9cb73F20164e399958261c289Eb5F9846f4D1404',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBelt4Belt',
    earnedTokenAddress: '0x8daCD48d25d8cA3688c903Bf77884d7b3C7A61ED',
    earnContractAddress: '0x8daCD48d25d8cA3688c903Bf77884d7b3C7A61ED',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'belt-4belt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Belt',
    assets: ['USDT', 'BUSD', 'USDC', 'DAI', '4BELT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratMultiLP',
    withdrawalFee: '0.01%',
    addLiquidityUrl: 'https://belt.fi/',
  },
];

export const moonbeamPools = [
  {
    id: 'stellaswap-stella-wglmr',
    name: 'STELLA-GLMR LP',
    token: 'STELLA-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x7F5Ac0FC127bcf1eAf54E3cd01b00300a0861a62',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapSTELLA-GLMR',
    earnedTokenAddress: '0xf0365AD372A35c375f7d7D98DdD10A50De84FcFd',
    earnContractAddress: '0xf0365AD372A35c375f7d7D98DdD10A50De84FcFd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-stella-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['STELLA', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647351540,
  },
  {
    id: 'stellaswap-dai-usdc',
    name: 'DAI-USDC LP',
    token: 'DAI-USDC LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x5Ced2f8DD70dc25cbA10ad18c7543Ad9ad5AEeDD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapDAI-USDC',
    earnedTokenAddress: '0x8F718Dc40C6E6435B3A52eca8590B42C9A762Db9',
    earnContractAddress: '0x8F718Dc40C6E6435B3A52eca8590B42C9A762Db9',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x765277EebeCA2e31912C9946eAe1021199B39C61/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958428,
  },
  {
    id: 'stellaswap-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x8BC3CceeF43392B315dDD92ba30b435F79b66b9e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapUSDC-USDT',
    earnedTokenAddress: '0xDE73E0b0a1Eda21e255b8a162514388b02694a5e',
    earnContractAddress: '0xDE73E0b0a1Eda21e255b8a162514388b02694a5e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647522606,
  },
  {
    id: 'stellaswap-usdc-wglmr',
    name: 'USDC-GLMR LP',
    token: 'USDC-GLMR LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x555B74dAFC4Ef3A5A1640041e3244460Dc7610d1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapUSDC-GLMR',
    earnedTokenAddress: '0x2104E80cB83B54b4402cc152f8B56184bC3008Cd',
    earnContractAddress: '0x2104E80cB83B54b4402cc152f8B56184bC3008Cd',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-wglmr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'GLMR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/ETH',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647355452,
  },
  {
    id: 'stellaswap-usdc-bnb',
    name: 'USDC-BNB LP',
    token: 'USDC-BNB LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0xAc2657ba28768FE5F09052f07A9B7ea867A4608f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapUSDC-BNB',
    earnedTokenAddress: '0x4cE1cd90611bD83301728E186c230a5A80A999d3',
    earnContractAddress: '0x4cE1cd90611bD83301728E186c230a5A80A999d3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647524406,
  },
  {
    id: 'stellaswap-usdc-eth',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH LP',
    tokenDescription: 'Stellaswap',
    tokenAddress: '0x0Aa48bF937ee8F41f1a52D225EF5A6F6961e39FA',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXStellaswapUSDC-ETH',
    earnedTokenAddress: '0x37c5Ab1057a51225DFA327058E100cd98B1B682A',
    earnContractAddress: '0x37c5Ab1057a51225DFA327058E100cd98B1B682A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'stellaswap-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Stellaswap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.stellaswap.com/exchange/add/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
    buyTokenUrl: 'https://app.stellaswap.com/exchange/swap',
    createdAt: 1647958674,
  },
  {
    id: 'beamswap-usdc-busd',
    name: 'USDC-BUSD LP',
    token: 'USDC-BUSD TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xa0799832FB2b9F18Acf44B92FbbEDCfD6442DD5e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBeamUSDC-BUSD',
    earnedTokenAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    earnContractAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644599298,
  },
  {
    id: 'beamswap-dai-usdc',
    name: 'USDC-DAI LP',
    token: 'USDC-DAI TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x7EF9491774a81f6dB7Bb759Fe2F645c334dCf5b1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooBeamUSDC-DAI',
    earnedTokenAddress: '0xf605c9826474e4aBCE94b855d911259C1a2f0A91',
    earnContractAddress: '0xf605c9826474e4aBCE94b855d911259C1a2f0A91',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/0x765277EebeCA2e31912C9946eAe1021199B39C61',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b&outputCurrency=0x765277EebeCA2e31912C9946eAe1021199B39C61',
    createdAt: 1644598824,
  },
  {
    id: 'beamswap-usdc-usdt',
    name: 'USDC-USDT LP',
    token: 'USDC-USDT TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0xA35B2c07Cb123EA5E1B9c7530d0812e7e03eC3c1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBeamUSDC-USDT',
    earnedTokenAddress: '0xb50b87c75f780A21d34125072c162934a6013ba0',
    earnContractAddress: '0xb50b87c75f780A21d34125072c162934a6013ba0',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644600000,
  },
  {
    id: 'beamswap-busd-bnb',
    name: 'BUSD-BNB LP',
    token: 'BUSD-BNB TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x34A1F4AB3548A92C6B32cd778Eed310FcD9A340D',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBeamBUSD-BNB',
    earnedTokenAddress: '0x6710f02b0B7525d9A7868Cd628F824Acf6741000',
    earnContractAddress: '0x6710f02b0B7525d9A7868Cd628F824Acf6741000',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-busd-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['BUSD', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055&outputCurrency=0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
    createdAt: 1644599646,
  },
  {
    id: 'beamswap-usdc-eth',
    name: 'USDC-ETH LP',
    token: 'USDC-ETH TLP',
    tokenDescription: 'Beamswap',
    tokenAddress: '0x6BA3071760d46040FB4dc7B627C9f68efAca3000',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXBeamUSDC-ETH',
    earnedTokenAddress: '0x712Bb032389fD161Af8FCDc3AEB83e921AE8DEf3',
    earnContractAddress: '0x712Bb032389fD161Af8FCDc3AEB83e921AE8DEf3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'beamswap-usdc-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beamswap',
    assets: ['USDC', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    addLiquidityUrl:
      'https://app.beamswap.io/exchange/add/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    buyTokenUrl:
      'https://app.beamswap.io/exchange/swap?inputCurrency=0xfA9343C3897324496A05fC75abeD6bAC29f8A40f&outputCurrency=0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
    createdAt: 1644599832,
  },
];

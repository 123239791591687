export const cronosPools = [
  {
    id: 'vvs-cro-usdc',
    name: 'CRO-USDC',
    token: 'CRO-USDC LP',
    tokenDescription: 'VVS',
    tokenAddress: '0xe61Db569E231B3f5530168Aa2C9D50246525b6d6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'TokenXVvsCRO-USDC',
    earnedTokenAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    earnContractAddress: '0x541cCcAF6Af423fdBD32468E4B1dff841A6347b3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['CRO', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  },
  // {
  //   id: 'crona-cro-usdt',
  //   name: 'USDT-CRO',
  //   token: 'USDT-CRO LP',
  //   tokenDescription: 'CronaSwap',
  //   tokenAddress: '0x19Dd1683e8c5F6Cc338C1438f2D25EBb4e0b0b08',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'tokenXCronaCRO-USDT',
  //   earnedTokenAddress: '0xf605c9826474e4aBCE94b855d911259C1a2f0A91',
  //   earnContractAddress: '0xf605c9826474e4aBCE94b855d911259C1a2f0A91',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'crona-cro-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'CronaSwap',
  //   assets: ['USDT', 'CRO'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   buyTokenUrl:
  //     'https://app.cronaswap.org/swap?outputCurrency=0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  //   addLiquidityUrl: 'https://app.cronaswap.org/add/CRO/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
  // },
  {
    id: 'vvs-cro-dai',
    name: 'CRO-DAI',
    token: 'CRO-DAI LP',
    tokenDescription: 'VVS',
    tokenAddress: '0x3Eb9FF92e19b73235A393000C176c8bb150F1B20',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsCRO-DAI',
    earnedTokenAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    earnContractAddress: '0x93b83636695c8E8a36547317E1291C8814635c15',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['CRO', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
  },
  {
    id: 'vvs-tonic-cro',
    name: 'TONIC-CRO',
    token: 'TONIC-CRO LP',
    tokenDescription: 'VVS',
    tokenAddress: '0x4B377121d968Bf7a62D51B96523d59506e7c2BF0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsTONIC-CRO',
    earnedTokenAddress: '0x712Bb032389fD161Af8FCDc3AEB83e921AE8DEf3',
    earnContractAddress: '0x712Bb032389fD161Af8FCDc3AEB83e921AE8DEf3',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-tonic-cro',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['TONIC', 'CRO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2',
  },
  {
    id: 'vvs-cro-eth',
    name: 'CRO-ETH',
    token: 'CRO-ETH LP',
    tokenDescription: 'VVS',
    tokenAddress: '0xA111C17f8B8303280d3EB01BBcd61000AA7F39F9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsCRO-ETH',
    earnedTokenAddress: '0x98E9610bbCe499CE2AE6155bA1197B56C98CaCCf',
    earnContractAddress: '0x98E9610bbCe499CE2AE6155bA1197B56C98CaCCf',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-eth',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['CRO', 'ETH'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
  },
  {
    id: 'vvs-cro-btc',
    name: 'CRO-BTC',
    token: 'CRO-BTC LP',
    tokenDescription: 'VVS',
    tokenAddress: '0x8F09fFf247B8fDB80461E5Cf5E82dD1aE2EBd6d7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsCRO-BTC',
    earnedTokenAddress: '0xbC85546Ae3ead99164e565d3d8E2De498084DAA8',
    earnContractAddress: '0xbC85546Ae3ead99164e565d3d8E2De498084DAA8',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-btc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['CRO', 'WBTC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0x062E66477Faf219F25D27dCED647BF57C3107d52',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0x062E66477Faf219F25D27dCED647BF57C3107d52',
  },
  {
    id: 'vvs-vvs-usdt',
    name: 'VVS-USDT',
    token: 'VVS-USDT LP',
    tokenDescription: 'VVS',
    tokenAddress: '0x280aCAD550B2d3Ba63C8cbff51b503Ea41a1c61B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsVVS-USDT',
    earnedTokenAddress: '0xFF8EeDf7F8a38773cc7A54c7f36e6eda0675F5dA',
    earnContractAddress: '0xFF8EeDf7F8a38773cc7A54c7f36e6eda0675F5dA',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-vvs-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['VVS', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0x66e428c3f67a68878562e79A0234c1F83c208770',
    addLiquidityUrl:
      'https://vvs.finance/add/0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03/0x66e428c3f67a68878562e79A0234c1F83c208770',
  },
  {
    id: 'vvs-cro-doge',
    name: 'DOGE-CRO',
    token: 'DOGE-CRO LP',
    tokenDescription: 'VVS',
    tokenAddress: '0x2A560f2312CB56327AD5D65a03F1bfEC10b62075',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsCRO-DOGE',
    earnedTokenAddress: '0x881Fda5Cd43FdD77C65D649d6bA5cf71aa652A76',
    earnContractAddress: '0x881Fda5Cd43FdD77C65D649d6bA5cf71aa652A76',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-doge',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['DOGE', 'CRO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0x1a8E39ae59e5556B56b76fCBA98d22c9ae557396',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0x1a8E39ae59e5556B56b76fCBA98d22c9ae557396',
  },
  {
    id: 'vvs-cro-shib',
    name: 'SHIB-CRO',
    token: 'SHIB-CRO LP',
    tokenDescription: 'VVS',
    tokenAddress: '0xc9eA98736dbC94FAA91AbF9F4aD1eb41e7fb40f4',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'tokenXVvsCRO-SHIB',
    earnedTokenAddress: '0x60e8Ca7de831CE7916F880A597dDC72Ba404164d',
    earnContractAddress: '0x60e8Ca7de831CE7916F880A597dDC72Ba404164d',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'vvs-cro-shib',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'VVS',
    assets: ['SHIB', 'CRO'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://vvs.finance/swap?outputCurrency=0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
    addLiquidityUrl: 'https://vvs.finance/add/CRO/0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
  },
];

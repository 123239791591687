export const moonriverPools = [
  {
    id: 'movr-bifi-maxi',
    logo: 'single-assets/BIFI.png',
    name: 'BIFI Maxi',
    token: 'BIFI',
    tokenDescription: 'Beefy.Finance',
    tokenAddress: '0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooMoonriverBIFI',
    earnedTokenAddress: '0xc9a509dA14525Ad3710e9448a0839EE2e90E48B1',
    earnContractAddress: '0xc9a509dA14525Ad3710e9448a0839EE2e90E48B1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'BIFI',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Beefy.Finance',
    assets: ['BIFI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
  },
  {
    id: 'sushi-mr-eth-usdt',
    name: 'ETH-USDT',
    token: 'ETH-USDT SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xB0A594e76A876DE40a7fda9819E5c4Ec6d9Fd222',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-USDT',
    earnedTokenAddress: '0x0DB1744a6D5bb4aB5FeaE826af120c08d84eefd4',
    earnContractAddress: '0x0DB1744a6D5bb4aB5FeaE826af120c08d84eefd4',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-eth-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c/0xb44a9b6905af7c801311e8f4e76932ee959c663c',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xb44a9b6905af7c801311e8f4e76932ee959c663c',
  },
  {
    id: 'sushi-mr-mim-wmovr',
    name: 'MIM-WMOVR',
    token: 'MIM-WMOVR SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x6dC6D33331e9d5DeEf449C8CAb9e34D687BEA80E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiMIM-WMOVR',
    earnedTokenAddress: '0x9f3371AA6F7b48485Cb9eb3D6A79ea9298565410',
    earnContractAddress: '0x9f3371AA6F7b48485Cb9eb3D6A79ea9298565410',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-mim-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['MIM', 'WMOVR_SUSHI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x0caE51e1032e8461f4806e26332c030E34De3aDb/0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
  },
  {
    id: 'sushi-mr-frax-usdc',
    name: 'FRAX-USDC',
    token: 'FRAX-USDC SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x310C4d18640aF4878567c4A31cB9cBde7Cd234A3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiFRAX-USDC',
    earnedTokenAddress: '0x27d99A6B13cC4f5F55f3Fb9Bd6F7a02F9EFCD820',
    earnContractAddress: '0x27d99A6B13cC4f5F55f3Fb9Bd6F7a02F9EFCD820',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-frax-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['FRAX', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x1A93B23281CC1CDE4C4741353F3064709A16197d/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  },
  {
    id: 'sushi-mr-eth-wmovr',
    name: 'ETH-WMOVR',
    token: 'ETH-WMOVR SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xD4Ce5671b842573EDA99164971AFaA034245f4D8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-WMOVR',
    earnedTokenAddress: '0x65bb8496DDfE7470516C0Ed014C0eDc7C7973676',
    earnContractAddress: '0x65bb8496DDfE7470516C0Ed014C0eDc7C7973676',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-eth-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'WMOVR_SUSHI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c/0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
  },
  {
    id: 'sushi-mr-usdt-usdc',
    name: 'USDT-USDC',
    token: 'USDT-USDC SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x930B0464411DE2296E3FD3eeE30ab989F635645E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiUSDT-USDC',
    earnedTokenAddress: '0x1f1FAfB086C6e1e900f18fccbeD5BA727d09F193',
    earnContractAddress: '0x1f1FAfB086C6e1e900f18fccbeD5BA727d09F193',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-usdt-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['USDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0xB44a9B6905aF7c801311e8F4E76932ee959c663C/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  },
  {
    id: 'sushi-mr-eth-bnb',
    name: 'ETH-BNB.bsc',
    token: 'ETH-BNB.bsc SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x69B4FbD8c8Cf74720A62A5e92e2528c3f895cE10',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-BNB',
    earnedTokenAddress: '0x391db851d684b1D1D8b034d678Db4C5701AFfe2e',
    earnContractAddress: '0x391db851d684b1D1D8b034d678Db4C5701AFfe2e',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-eth-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'BNBbsc'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C/0x868892CCcEdbfF0B028F3b3595205Ea91b99376B',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0x868892CCcEdbfF0B028F3b3595205Ea91b99376B',
  },
  {
    id: 'sushi-mr-eth-wbtc',
    name: 'ETH-WBTC',
    token: 'ETH-WBTC SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0x85657487978d65BB7881e9dAD3aA119E6E6705cE',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-WBTC',
    earnedTokenAddress: '0xB4CDa8f7E91a5306c0e66c58F43aC2C987aA7268',
    earnContractAddress: '0xB4CDa8f7E91a5306c0e66c58F43aC2C987aA7268',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-eth-wbtc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'WBTCeth'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C/0xE6a991Ffa8CfE62B0bf6BF72959A3d4f11B2E0f5',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0xE6a991Ffa8CfE62B0bf6BF72959A3d4f11B2E0f5',
  },
  {
    id: 'sushi-mr-eth-dai',
    name: 'ETH-DAI',
    token: 'ETH-DAI SLP',
    tokenDescription: 'Sushi',
    tokenAddress: '0xC6Ca9C83c07A7a3A5461c817eA5210723508A9fD',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSushiETH-DAI',
    earnedTokenAddress: '0x817336ccfa4B221342Ef6E3dCFA3523c8BFf76D6',
    earnContractAddress: '0x817336ccfa4B221342Ef6E3dCFA3523c8BFf76D6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'sushi-mr-eth-dai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SushiSwap',
    assets: ['ETH', 'DAI'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    addLiquidityUrl:
      'https://app.sushi.com/add/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C/0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
    buyTokenUrl:
      'https://app.sushi.com/swap?outputCurrency=0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
  },
  {
    id: 'solarbeam-mim-wmovr',
    name: 'MIM-MOVR',
    token: 'MIM-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x9432B25fBD8a37e5A1300e36a96BD14E1E6f5c90',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMIM-MOVR',
    earnedTokenAddress: '0x9af278BC0A7861F95d93913390945c2127eb80a6',
    earnContractAddress: '0x9af278BC0A7861F95d93913390945c2127eb80a6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-mim-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['MIM', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x98878B06940aE243284CA214f92Bb71a2b032B8A&outputCurrency=0x0caE51e1032e8461f4806e26332c030E34De3aDb',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x0caE51e1032e8461f4806e26332c030E34De3aDb',
  },
  {
    id: 'solarbeam-frax-rome',
    name: 'ROME-FRAX',
    token: 'ROME-FRAX LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x069C2065100b4D3D982383f7Ef3EcD1b95C05894',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarFRAX-ROME',
    earnedTokenAddress: '0x4A619d9DE52e669A09996C6e107409da53b794d1',
    earnContractAddress: '0x4A619d9DE52e669A09996C6e107409da53b794d1',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-frax-rome',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['ROME', 'FRAX'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x1A93B23281CC1CDE4C4741353F3064709A16197d&outputCurrency=0x4a436073552044D5f2f49B176853ad3Ad473d9d6',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0x1A93B23281CC1CDE4C4741353F3064709A16197d/0x4a436073552044D5f2f49B176853ad3Ad473d9d6',
  },
  {
    id: 'solarbeam-wmovr-mai',
    name: 'MAI-MOVR',
    token: 'MAI-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xcD450B46dbED11C4F1cf159457B28ed7e2Ed7fBB',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMOVR-MAI',
    earnedTokenAddress: '0x3c67955C1D0Dd4FFC5b52a4003769b36582f9AbF',
    earnContractAddress: '0x3c67955C1D0Dd4FFC5b52a4003769b36582f9AbF',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wmovr-mai',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['MAI', 'MOVR'],
    risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_LOW', 'MCAP_LOW', 'AUDIT', 'CONTRACTS_VERIFIED'],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x98878B06940aE243284CA214f92Bb71a2b032B8A&outputCurrency=0xFb2019DfD635a03cfFF624D210AEe6AF2B00fC2C',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0xFb2019DfD635a03cfFF624D210AEe6AF2B00fC2C',
  },
  {
    id: 'solarbeam-frax-wmovr-2',
    name: 'FRAX-MOVR',
    token: 'FRAX-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x2cc54b4A3878e36E1C754871438113C1117a3ad7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarFRAX-WMOVR',
    earnedTokenAddress: '0xf8e9774AE3cc7aE3eE11161cC1313252A0784E18',
    earnContractAddress: '0xf8e9774AE3cc7aE3eE11161cC1313252A0784E18',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-frax-wmovr-2',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['FRAX', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x1A93B23281CC1CDE4C4741353F3064709A16197d',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x1A93B23281CC1CDE4C4741353F3064709A16197d',
  },
  {
    id: 'finn-wmovr-finn',
    name: 'FINN-MOVR',
    token: 'FINN-MOVR LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xbBe2f34367972Cb37ae8dea849aE168834440685',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnMOVR-FINN',
    earnedTokenAddress: '0xFaA15A1B4DAB85d35e912f88127b9986638a1265',
    earnContractAddress: '0xFaA15A1B4DAB85d35e912f88127b9986638a1265',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wmovr-finn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['FINN', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/MOVR/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-eol',
    logo: 'single-assets/FINN.png',
    name: 'FINN',
    token: 'FINN',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinn',
    earnedTokenAddress: '0xe173782e5Cc62f1C3277749EEa9c5Daf182d7C66',
    earnContractAddress: '0xe173782e5Cc62f1C3277749EEa9c5Daf182d7C66',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'FINN',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'noReason',
    platform: 'Huckleberry',
    assets: ['FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
  },
  {
    id: 'finn-wmovr-usdt',
    name: 'MOVR-USDT',
    token: 'MOVR-USDT FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x037f019B6d98A61AF80c0da5ecFdc23612B0C134',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnMOVR-USDT',
    earnedTokenAddress: '0xa6cF91E75c43798218867775513D112E00a63B45',
    earnContractAddress: '0xa6cF91E75c43798218867775513D112E00a63B45',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wmovr-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['MOVR', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/MOVR/0xE936CAA7f6d9F5C9e907111FCAf7c351c184CDA7',
  },
  {
    id: 'finn-eth-wmovr',
    name: 'ETH-MOVR',
    token: 'ETH-MOVR FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xBaf9eB00574C42Cf678778790b04705B435B65F8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnETH-MOVR',
    earnedTokenAddress: '0x9FE2921Fe00E48B88bdE16102627E750924b96bb',
    earnContractAddress: '0x9FE2921Fe00E48B88bdE16102627E750924b96bb',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-eth-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['ETH', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/MOVR/0x576fDe3f61B7c97e381c94e7A03DBc2e08Af1111',
  },
  {
    id: 'finn-wbtc-wmovr',
    name: 'WBTC-MOVR',
    token: 'WBTC-MOVR FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x3fAF6e9Bf107199dB347B146F80A68CBD0B598B2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnWBTC-MOVR',
    earnedTokenAddress: '0x570e14018E6ad396fF39FF5e3Be8970d6be99cAC',
    earnContractAddress: '0x570e14018E6ad396fF39FF5e3Be8970d6be99cAC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wbtc-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['WBTC', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/MOVR/0x78F811A431D248c1EDcF6d95ec8551879B2897C3',
  },
  {
    id: 'finn-usdc-usdt',
    name: 'USDC-USDT',
    token: 'USDC-USDT FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xce634Ae7191aB885Fb4ce8d1ed58f80EA988D52a',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnUSDC-USDT',
    earnedTokenAddress: '0x0a2289a7eE8A1f2A6B0E2320f47D8d0ff143f436',
    earnContractAddress: '0x0a2289a7eE8A1f2A6B0E2320f47D8d0ff143f436',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-usdc-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['USDC', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_LARGE',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x748134b5F553F2bcBD78c6826De99a70274bDEb3/0xE936CAA7f6d9F5C9e907111FCAf7c351c184CDA7',
  },
  {
    id: 'finn-wan-wmovr',
    name: 'WAN-MOVR',
    token: 'WAN-MOVR LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x0Df4cD5fD2f69E54A3E6d13e5cad54f1eB995E8c',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnWAN-MOVR',
    earnedTokenAddress: '0xAfCcf43F6A3D99F20A1788ae141b6F8FbE8d5E4B',
    earnContractAddress: '0xAfCcf43F6A3D99F20A1788ae141b6F8FbE8d5E4B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wan-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['WAN', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_SMALL',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/MOVR/0x41562ae242d194247389152aCAa7a9397136b09F',
  },
  {
    id: 'finn-eth-finn-eol',
    name: 'ETH-FINN',
    token: 'ETH-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x03bF745884513869AFE685af099e8c738F3697E3',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnETH-FINN',
    earnedTokenAddress: '0x7e95e7b74AF3d966baBD44aa6229fA4a3F0Ad286',
    earnContractAddress: '0x7e95e7b74AF3d966baBD44aa6229fA4a3F0Ad286',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-eth-finn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Huckleberry',
    assets: ['ETH', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x576fDe3f61B7c97e381c94e7A03DBc2e08Af1111/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-wbtc-finn-eol',
    name: 'WBTC-FINN',
    token: 'WBTC-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x0A1Af960473e170773a33012bf2C291e6f7e3E2C',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnWBTC-FINN',
    earnedTokenAddress: '0x8A9Ad76449781BeF39ae4dA70944fbbADdEB6852',
    earnContractAddress: '0x8A9Ad76449781BeF39ae4dA70944fbbADdEB6852',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wbtc-finn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Huckleberry',
    assets: ['WBTC', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x78F811A431D248c1EDcF6d95ec8551879B2897C3/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-usdt',
    name: 'FINN-USDT',
    token: 'FINN-USDT LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xBE1C572E40150EaCf046ceFB4A4552FbB13E68f8',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-USDT',
    earnedTokenAddress: '0x48bF3a071098a09C7D00379b4DBC69Ab6Da83a36',
    earnContractAddress: '0x48bF3a071098a09C7D00379b4DBC69Ab6Da83a36',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['FINN', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0xE936CAA7f6d9F5C9e907111FCAf7c351c184CDA7/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-avax',
    name: 'AVAX-FINN',
    token: 'AVAX-FINN LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x10300C5FD4Af0F81fC8DA3f48e44dbccd189f253',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-AVAX',
    earnedTokenAddress: '0xD01dfb76F241A91547CA3AF0ece41547549e7393',
    earnContractAddress: '0xD01dfb76F241A91547CA3AF0ece41547549e7393',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-avax',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['AVAX', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0xc005A7A1502c9DE16CCDaBA7CDA0cee4Ac304993/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-ftm',
    name: 'FTM-FINN',
    token: 'FTM-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x135C0c09517AF7445a4B27976d3549B996483f4e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-FTM',
    earnedTokenAddress: '0x79F0283A7078a9068C4cAE1644963f90679A1707',
    earnContractAddress: '0x79F0283A7078a9068C4cAE1644963f90679A1707',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['FTM', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0xd924f195C6012C301f42290cf0fdE385A66039DE/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-bnb',
    name: 'BNB-FINN',
    token: 'BNB-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x3F7db2e5Ca0Fe2372a9Bc762C9984213B937c248',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-BNB',
    earnedTokenAddress: '0xc59038F4102905b343AAD042DDA4Cc30DB3FBDdE',
    earnContractAddress: '0xc59038F4102905b343AAD042DDA4Cc30DB3FBDdE',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-bnb',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['BNB', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0xf47002E6151E06554b8f16E9Cc704edD3fC078b8/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-xrp',
    name: 'XRP-FINN',
    token: 'XRP-FINN LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0x7491D6e5aC2785Fc9c873922F9B424fCA7720519',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-XRP',
    earnedTokenAddress: '0xb5e6F0914c6e3e2bf7eAD50163715D5bFF23F578',
    earnContractAddress: '0xb5e6F0914c6e3e2bf7eAD50163715D5bFF23F578',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-xrp',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['XRP', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x9D5bc9B873AeD984e2B6A64d4792249D68BbA2Fe/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-wan-finn-eol',
    name: 'WAN-FINN',
    token: 'WAN-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xF3d89313F508f877f086b7FFA8BeA6926b886B5f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnWAN-FINN',
    earnedTokenAddress: '0x3d91f38Abb9abEdd87099faF48eb2B3230f4f259',
    earnContractAddress: '0x3d91f38Abb9abEdd87099faF48eb2B3230f4f259',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-wan-finn',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    retireReason: 'rewards',
    platform: 'Huckleberry',
    assets: ['WAN', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x41562ae242d194247389152aCAa7a9397136b09F/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-finn-doge',
    name: 'DOGE-FINN',
    token: 'DOGE-FINN FLP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xB4939f65f27cac1e220D8A86E0274860aDa9d825',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnFINN-DOGE',
    earnedTokenAddress: '0x16508967D3F7693DD1D023Dbd442Bdb2304f8289',
    earnContractAddress: '0x16508967D3F7693DD1D023Dbd442Bdb2304f8289',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-finn-doge',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['DOGE', 'FINN'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0xb668Cd490a4421F993f93be2819A922DBbB32804/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-usdc-finn',
    name: 'FINN-USDC',
    token: 'FINN-USDC LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xb04492345b0618acFf27AC27CeC4244fBdd30208',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnUSDC-FINN',
    earnedTokenAddress: '0xcCa4B9dEbfE7EA2221A8238499D66Ee5f3623122',
    earnContractAddress: '0xcCa4B9dEbfE7EA2221A8238499D66Ee5f3623122',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-usdc-finn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['FINN', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x748134b5F553F2bcBD78c6826De99a70274bDEb3/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'finn-dot-finn',
    name: 'FINN-DOT',
    token: 'FINN-DOT LP',
    tokenDescription: 'Huckleberry',
    tokenAddress: '0xF09211fb5eD5019b072774cfD7Db0c9f4ccd5Be0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooFinnDOT-FINN',
    earnedTokenAddress: '0xC8C86F0A4879A0479554c7599dDe3ef51614996E',
    earnContractAddress: '0xC8C86F0A4879A0479554c7599dDe3ef51614996E',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'finn-dot-finn',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'Huckleberry',
    assets: ['FINN', 'DOT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl: 'https://www.huckleberry.finance/#/swap',
    addLiquidityUrl:
      'https://www.huckleberry.finance/#/add/0x15B9CA9659F5dfF2b7d35a98dd0790a3CBb3D445/0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
  },
  {
    id: 'solarbeam-wbtc-wmovr',
    name: 'WBTC-MOVR',
    token: 'WBTC-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x32E33B774372c700da12F2D0F7A834045F5651B2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarWBTC-MOVR',
    earnedTokenAddress: '0x0FB94775d16e14627678391D24E4576fdA05E3ed',
    earnContractAddress: '0x0FB94775d16e14627678391D24E4576fdA05E3ed',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wbtc-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['WBTC', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
  },
  {
    id: 'solarbeam-eth-wmovr',
    name: 'ETH-MOVR',
    token: 'ETH-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x0d171b55fC8d3BDDF17E376FdB2d90485f900888',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarETH-MOVR',
    earnedTokenAddress: '0xD25c56DAbcda719F1c67fE8fc0760f8B942aC95C',
    earnContractAddress: '0xD25c56DAbcda719F1c67fE8fc0760f8B942aC95C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-eth-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['ETH', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
  },
  {
    id: 'solarbeam-bnb-wmovr',
    name: 'BNB-MOVR',
    token: 'BNB-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xBe2aBe58eDAae96B4303F194d2fAD5233BaD3d87',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarBNB-MOVR',
    earnedTokenAddress: '0x76cb930dceb002838E1210bCEC279005883648Ba',
    earnContractAddress: '0x76cb930dceb002838E1210bCEC279005883648Ba',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-bnb-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['BNB', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
  },
  {
    id: 'solarbeam-solar',
    logo: 'single-assets/SOLAR.png',
    name: 'SOLAR',
    token: 'SOLAR',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarSOLAR',
    earnedTokenAddress: '0xE3fCfF08de3fc40a7fb3674533FDb7a64027BF00',
    earnContractAddress: '0xE3fCfF08de3fc40a7fb3674533FDb7a64027BF00',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'SOLAR',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['SOLAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
  },
  {
    id: 'solarbeam-solar-wmovr',
    name: 'SOLAR-MOVR',
    token: 'SOLAR-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x7eDA899b3522683636746a2f3a7814e6fFca75e1',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarSOLAR-MOVR',
    earnedTokenAddress: '0x1b6Bc65dBd597220DD0e8d3D8f976F0D18DfffB6',
    earnContractAddress: '0x1b6Bc65dBd597220DD0e8d3D8f976F0D18DfffB6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-solar-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['SOLAR', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
  },
  {
    id: 'solarbeam-solar-usdc',
    name: 'SOLAR-USDC',
    token: 'SOLAR-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xdb66BE1005f5Fe1d2f486E75cE3C50B52535F886',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarSOLAR-USDC',
    earnedTokenAddress: '0x2e8409eE2a8635f5EABd3a86E248252291285c40',
    earnContractAddress: '0x2e8409eE2a8635f5EABd3a86E248252291285c40',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-solar-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['SOLAR', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D&outputCurrency=0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
  },
  {
    id: 'solarbeam-wmovr-usdc',
    name: 'MOVR-USDC',
    token: 'MOVR-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xe537f70a8b62204832B8Ba91940B77d3f79AEb81',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMOVR-USDC',
    earnedTokenAddress: '0x78Dc4b7C7A89812fb337dD8C3B0ccB3e04E02D7C',
    earnContractAddress: '0x78Dc4b7C7A89812fb337dD8C3B0ccB3e04E02D7C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wmovr-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['MOVR', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  },
  {
    id: 'solarbeam-dai-usdc',
    name: 'DAI-USDC',
    token: 'DAI-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xFE1b71BDAEE495dCA331D28F5779E87bd32FbE53',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarDAI-USDC',
    earnedTokenAddress: '0x93583e1F4cdbA714FA4F8ddAD1d3b85C2CE1A213',
    earnContractAddress: '0x93583e1F4cdbA714FA4F8ddAD1d3b85C2CE1A213',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-dai-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['DAI', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844&outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
  },
  {
    id: 'solarbeam-busd-usdc',
    name: 'BUSD-USDC',
    token: 'BUSD-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x384704557F73fBFAE6e9297FD1E6075FC340dbe5',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarBUSD-USDC',
    earnedTokenAddress: '0x9d8469591F428e8a0bDD0B59bC267C35759Ee4a2',
    earnContractAddress: '0x9d8469591F428e8a0bDD0B59bC267C35759Ee4a2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-busd-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['BUSD', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D&outputCurrency=0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
  },
  {
    id: 'solarbeam-eth-usdc',
    name: 'ETH-USDC',
    token: 'ETH-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xA0D8DFB2CC9dFe6905eDd5B71c56BA92AD09A3dC',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarETH-USDC',
    earnedTokenAddress: '0x932009984bd2a7dA8C6396694E811Da5C0952d05',
    earnContractAddress: '0x932009984bd2a7dA8C6396694E811Da5C0952d05',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-eth-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['ETH', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D&outputCurrency=0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
  },
  {
    id: 'solarbeam-bnb-busd',
    name: 'BNB-BUSD',
    token: 'BNB-BUSD LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xfb1d0D6141Fc3305C63f189E39Cc2f2F7E58f4c2',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarBNB-BUSD',
    earnedTokenAddress: '0xD70e9E716DBaaCf4d292D89AB24126af8546cCEC',
    earnContractAddress: '0xD70e9E716DBaaCf4d292D89AB24126af8546cCEC',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-bnb-busd',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['BNB', 'BUSD'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c&outputCurrency=0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c/0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
  },
  {
    id: 'solarbeam-wbtc-usdc',
    name: 'WBTC-USDC',
    token: 'WBTC-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x83d7a3fc841038E8c8F46e6192BBcCA8b19Ee4e7',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarWBTC-USDC',
    earnedTokenAddress: '0xd91F3A412F52F9E7ccF6987A3De75978721752E6',
    earnContractAddress: '0xd91F3A412F52F9E7ccF6987A3De75978721752E6',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wbtc-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['WBTC', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8&outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  },
  {
    id: 'solarbeam-usdt-usdc',
    name: 'USDT-USDC',
    token: 'USDT-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x2a44696DDc050f14429bd8a4A05c750C6582bF3b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarUSDT-USDC',
    earnedTokenAddress: '0xe4626Cb852c50608630a9216bBf0735308f76388',
    earnContractAddress: '0xe4626Cb852c50608630a9216bBf0735308f76388',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-usdt-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['USDT', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_HIGH',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0xB44a9B6905aF7c801311e8F4E76932ee959c663C&outputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xB44a9B6905aF7c801311e8F4E76932ee959c663C/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
  },
  {
    id: 'solarbeam-mim-usdc',
    name: 'MIM-USDC',
    token: 'MIM-USDC LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x9051fB701d6D880800e397e5B5d46FdDfAdc7056',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMIM-USDC',
    earnedTokenAddress: '0xd21b58f3DDCbE92A108F33e980FEe1Afd1221578',
    earnContractAddress: '0xd21b58f3DDCbE92A108F33e980FEe1Afd1221578',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-mim-usdc',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['MIM', 'USDC'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D&outputCurrency=0x0caE51e1032e8461f4806e26332c030E34De3aDb',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/0x0caE51e1032e8461f4806e26332c030E34De3aDb',
  },
  {
    id: 'solarbeam-matic-wmovr',
    name: 'MATIC-MOVR',
    token: 'MATIC-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x29633cc367AbD9b16d327Adaf6c3538b6e97f6C0',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMATIC-MOVR',
    earnedTokenAddress: '0x99aeb31CB30DCfB85d50Adc708a740A0cfAceb94',
    earnContractAddress: '0x99aeb31CB30DCfB85d50Adc708a740A0cfAceb94',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-matic-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['MATIC', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x682F81e57EAa716504090C3ECBa8595fB54561D8',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x682F81e57EAa716504090C3ECBa8595fB54561D8',
  },
  {
    id: 'solarbeam-avax-wmovr',
    name: 'AVAX-MOVR',
    token: 'AVAX-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xb9a61ac826196AbC69A3C66ad77c563D6C5bdD7b',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarAVAX-MOVR',
    earnedTokenAddress: '0xA956903a735cCe4d0f4b5E4f5cfDCE8EE9cc2ed7',
    earnContractAddress: '0xA956903a735cCe4d0f4b5E4f5cfDCE8EE9cc2ed7',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-avax-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['AVAX', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x14a0243C333A5b238143068dC3A7323Ba4C30ECB',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x14a0243C333A5b238143068dC3A7323Ba4C30ECB',
  },
  {
    id: 'solarbeam-wmovr-ftm',
    name: 'FTM-MOVR',
    token: 'FTM-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x1eebed8F28A6865a76D91189FD6FC45F4F774d67',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMOVR-FTM',
    earnedTokenAddress: '0xf1093cEE4F759BFAaA8f276A6678D771D2B395d2',
    earnContractAddress: '0xf1093cEE4F759BFAaA8f276A6678D771D2B395d2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wmovr-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['FTM', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0xaD12daB5959f30b9fF3c2d6709f53C335dC39908',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0xaD12daB5959f30b9fF3c2d6709f53C335dC39908',
  },
  {
    id: 'solarbeam-wmovr-rib',
    name: 'RIB-MOVR',
    token: 'RIB-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x0acDB54E610dAbC82b8FA454b21AD425ae460DF9',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMOVR-RIB',
    earnedTokenAddress: '0x4405BbABe3Ce09BBb4cd13Cd51748AeDeBbA84a2',
    earnContractAddress: '0x4405BbABe3Ce09BBb4cd13Cd51748AeDeBbA84a2',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wmovr-rib',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['RIB', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0xbD90A6125a84E5C512129D622a75CDDE176aDE5E',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0xbD90A6125a84E5C512129D622a75CDDE176aDE5E',
  },
  {
    id: 'solarbeam-wmovr-relay',
    name: 'RELAY-MOVR',
    token: 'RELAY-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x9e0d90ebB44c22303Ee3d331c0e4a19667012433',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarMOVR-RELAY',
    earnedTokenAddress: '0xE09C534adE063222BDDC1EB5DD86bBF4bf194F90',
    earnContractAddress: '0xE09C534adE063222BDDC1EB5DD86bBF4bf194F90',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-wmovr-relay',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['RELAY', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0xAd7F1844696652ddA7959a49063BfFccafafEfe7',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0xAd7F1844696652ddA7959a49063BfFccafafEfe7',
  },
  {
    id: 'solarbeam-pets-wmovr',
    name: 'PETS-MOVR',
    token: 'PETS-MOVR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0x9f9a7a3f8F56AFB1a2059daE1E978165816cea44',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarPETS-MOVR',
    earnedTokenAddress: '0x004fd8d1667D4eE60945d97f91dAb1bbc32A099A',
    earnContractAddress: '0x004fd8d1667D4eE60945d97f91dAb1bbc32A099A',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-pets-wmovr',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SolarBeam',
    assets: ['PETS', 'MOVR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=ETH&outputCurrency=0x1e0F2A75Be02c025Bd84177765F89200c04337Da',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/ETH/0x1e0F2A75Be02c025Bd84177765F89200c04337Da',
  },
  {
    id: 'solarbeam-solar-rib-eol',
    name: 'RIB-SOLAR',
    token: 'RIB-SOLAR LP',
    tokenDescription: 'SolarBeam',
    tokenAddress: '0xf9b7495b833804e4d894fC5f7B39c10016e0a911',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'mooSolarSOLAR-RIB',
    earnedTokenAddress: '0x52EAcFcfd5Fa6E42DBaA70a477aA7eb67e6a1B9f',
    earnContractAddress: '0x52EAcFcfd5Fa6E42DBaA70a477aA7eb67e6a1B9f',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'solarbeam-solar-rib',
    oraclePrice: 0,
    depositsPaused: true,
    status: 'eol',
    platform: 'SolarBeam',
    assets: ['RIB', 'SOLAR'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    withdrawalFee: '0%',
    retireReason: 'rewards',
    buyTokenUrl:
      'https://app.solarbeam.io/exchange/swap?inputCurrency=0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B&outputCurrency=0xbD90A6125a84E5C512129D622a75CDDE176aDE5E',
    addLiquidityUrl:
      'https://app.solarbeam.io/exchange/add/0xbD90A6125a84E5C512129D622a75CDDE176aDE5E/0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
  },
];
